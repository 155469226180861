import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from "../../../environments/environment.prod";
import {NgxPermissionsService} from "ngx-permissions";
import {AuthService} from "./auth.service";
import {ErrorService} from "./error.service";
import {catchError} from "rxjs/operators";

@Injectable({providedIn: 'root'})
export class PermissionService {

  constructor(private http: HttpClient,
              public authService: AuthService,
              private errorService: ErrorService,
              private permissionService: NgxPermissionsService) {
    this.getMeRole();
  }

  getMeRole() {
    const currentUser = this.authService.currentUserValue;

    if (currentUser && currentUser.accessToken) {
      this.getRoleFromBackend().subscribe(data => {
        this.permissionService.loadPermissions(data);
      })
    }
  }

  getRoleFromBackend() {
    return this.http.get<string[]>(`${environment.baseUrl}${environment.role}`)
      .pipe(catchError(this.errorService.handleError));
  }

}
