<div class="layout-wrapper d-lg-flex">
  <div class="side-menu flex-lg-column me-lg-1 ms-lg-0">
    <!-- LOGO -->
    <div class="navbar-brand-box">
      <a (click)="activetab=2" class="logo logo-dark">
        <span class="logo-sm">
          <img src="assets/images/logo.png" alt="" height="30">
        </span>
      </a>

      <a (click)="activetab=2" class="logo logo-light">
        <span class="logo-sm">
          <img src="assets/images/logo.png" alt="" height="30">
        </span>
      </a>
    </div>
    <!-- end navbar-brand-box -->

    <!-- Start side-menu nav -->
    <div class="flex-lg-column my-auto">
      <ul class="nav nav-pills side-menu-nav justify-content-center" role="tablist">
        <li class="nav-item" ngbTooltip="{{'index.conversations' | translate}}"
            *ngxPermissionsOnly="['CONVERSATION_LIST']">
          <a class="nav-link active" id="pills-chat-tab" (click)="setActive(2)" href="javascript: void(0);"
             [ngClass]="{'active': activetab === 2}">
            <i class="ri-message-3-line"></i>
          </a>
        </li>
        <li class="nav-item" ngbTooltip="{{'index.groups' | translate}}" *ngxPermissionsOnly="['GROUP_LIST']">
          <a class="nav-link pills-groups-tab" id="pills-groups-tab" (click)="setActive(3)"
             href="javascript: void(0);" [ngClass]="{'active': activetab === 3}">
            <i class="ri-group-line"></i>
          </a>
        </li>
        <li class="nav-item" ngbTooltip="{{'index.drivers' | translate}}" placement="top"
            *ngxPermissionsOnly="['CONTACT_LIST']">
          <a class="nav-link" id="pills-contacts-tab" (click)="setActive(4)" href="javascript: void(0);"
             [ngClass]="{'active': activetab === 4}">
            <i class="ri-contacts-line"></i>
          </a>
        </li>
        <li class="nav-item" ngbTooltip="{{'index.forms' | translate}}" placement="top"
            *ngxPermissionsOnly="['FORM_LIST']">
          <a class="nav-link" id="pills-form-tab" (click)="setActive(5)" href="javascript: void(0);"
             [ngClass]="{'active': activetab === 5}">
            <i class="ri-list-check"></i>
          </a>
        </li>
        <li class="nav-item" ngbTooltip="{{'index.agreements' | translate}}" placement="top"
            *ngxPermissionsOnly="['AGREEMENT']">
          <a class="nav-link" id="pills-agreement-tab" (click)="setActive(6)" href="javascript: void(0);"
             [ngClass]="{'active': activetab === 6}">
            <i class="ri-user-smile-line"></i>
          </a>
        </li>

        <li class="nav-item dropdown profile-user-dropdown d-inline-block d-lg-none" ngbDropdown placement="top"
            (click)="logout()">
          <a class="nav-link dropdown-toggle" role="button">
            <i class="ri-logout-box-r-line rounded-circle"></i>
          </a>
        </li>
      </ul>
    </div>
    <!-- end side-menu nav -->

    <div class="flex-lg-column d-none d-lg-block">
      <ul class="nav side-menu-nav justify-content-center">

        <li class="nav-item btn-group dropup profile-user-dropdown" ngbDropdown placement="top-left">
          <a class="nav-link dropdown-toggle" href="javascript:void(0);" role="button" ngbDropdownToggle
             data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="ri-global-line"></i>
          </a>
          <div class="dropdown-menu" ngbDropdownMenu>
            @for (item of listLang; track $index) {
              <a class="dropdown-item" (click)="setLanguage(item.lang)" [ngClass]="{'active': lang === item.lang}"
                 href="javascript: void(0);">
                <img src="{{item.flag}}" alt="user-image" class="me-1" height="12"> <span
                class="align-middle">{{ item.text }}</span>
              </a>
            }
          </div>
        </li>

        <li class="nav-item">
          <a class="nav-link light-dark-mode" id="light-dark" href="javascript:void(0);" ngbTooltip="Light Mode">
            <i class="ri-sun-line theme-mode-icon layout-mode-dark" (click)="changeMode('dark')"></i>
            <i class="ri-sun-line theme-mode-icon layout-mode-light" (click)="changeMode('light')"></i>
          </a>
        </li>

        <li class="nav-item btn-group dropup profile-user-dropdown" (click)="logout()">
          <a class="nav-link dropdown-toggle" role="button">
            <i class="ri-logout-box-r-line rounded-circle"></i>
          </a>
        </li>
      </ul>
    </div>
    <!-- Side menu user -->
  </div>
  <!-- start chat-leftsidebar -->
  <div class="chat-leftsidebar me-lg-1 ms-lg-0">
    <div class="tab-content">

      <!-- Start chats tab-pane -->
      @if (activetab === 2) {
        <div id="pills-chat" role="tabpanel" aria-labelledby="pills-chat-tab">
          <!-- Start chats content -->
          <div>

            <!-- Start chat-message-list -->
            <app-conversation (conversation)="conversation = $event"></app-conversation>
            <!-- End chat-message-list -->
          </div>
          <!-- Start chats content -->
        </div>
      }
      <!-- End chats tab-pane -->

      <!-- Start groups tab-pane -->
      @if (activetab === 3) {
        <app-group></app-group>
      }
      <!-- End groups tab-pane -->

      <!-- Start contacts tab-pane -->
      @if (activetab === 4) {
        <div id="pills-contacts" role="tabpanel" aria-labelledby="pills-contacts-tab">
          <app-contacts></app-contacts>
        </div>
      }
      <!-- Start form tab-pane -->
      @if (activetab === 5) {
        <div id="pills-forms" role="tabpanel" aria-labelledby="pills-form-tab">
          <app-form></app-form>
        </div>
      }
      <!-- End contacts tab-pane -->
      <!-- Start form tab-pane -->
      @if (activetab === 6) {
        <div id="pills-agreement" role="tabpanel" aria-labelledby="pills-form-tab">
          <app-agreement></app-agreement>
        </div>
      }
      <!-- End contacts tab-pane -->
    </div>

  </div>

  <div class="chat-welcome-section">

  </div>
  <div class="w-100 justify-content-center">
    <app-conversation-detail [conversation]="conversation"></app-conversation-detail>
  </div>
  <!-- Start User chat -->

</div>
