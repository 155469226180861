export const environment = {
  production: true,
  //baseUrl: 'http://localhost:8080/',
  baseUrl: 'https://api.mdriver.eu/',
  assetBaseUrl: 'https://assets.mdriver.eu/',
  //assetBaseUrl: 'http://localhost:8082/',
  signIn: 'auth/signin',
  role: 'api/role/me',
  conversations: 'api/conversations',
  messages: 'api/messages/',
  addMessage: 'api/message',
  addMultiMessage: 'api/message/multi',
  markMessage: 'api/message/mark-seen',
  getGroups: 'api/groups',
  getGroupById: 'api/group/',
  createGroup: 'api/group',
  editGroup: 'api/group',
  getOperators: 'api/operators',
  createOperator: 'api/operator',
  editOperator: 'api/operator',
  deleteOperator: 'api/operator/',
  updateOperatorGroup: 'api/operator/group',
  uploadAsset: 'api/asset',
  getForm: 'api/forms/list',
  saveForm: 'api/form',
  createAgreement: 'api/agreement',
  deleteAgreement: 'api/agreement/',
  getAgreements: 'api/agreements',
};
