import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {User} from '../models/auth.models';
import {environment} from "../../../environments/environment.prod";
import {ErrorService} from "./error.service";
import {IMqttMessage, MqttService} from "ngx-mqtt";
import {AppContext} from "./app.context";
import {NotifierService} from "angular-notifier-3";

@Injectable({providedIn: 'root'})
export class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  subscription: Subscription;

  constructor(private http: HttpClient,
              private _mqttService: MqttService,
              private appContext: AppContext,
              private notifyService: NotifierService,
              private errorService: ErrorService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
    if (this.currentUserSubject.value != null) {
      this.subscribe();
    }
  }

  private subscribe() {
    this.subscription = this._mqttService.observe(`user_${this.currentUserValue.id}`).subscribe((message: IMqttMessage) => {
      var payload = JSON.parse(message.payload.toString());
      this.notifyService.notify('success', 'Nowa wiadomość');
      this.appContext.getConversations(null);
      if (this.appContext.selectedConversationId == payload.id) {

        this.appContext.refreshMessages.emit(payload.id);
      }

    });
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(username: string, password: string) {

    return this.http.post<any>(`${environment.baseUrl}${environment.signIn}`, {username, password})
      .pipe(map(user => {
        if (user && user.accessToken) {
          sessionStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);
          this.subscribe();
        }
        return user;
      })).pipe(catchError(this.errorService.handleError));
  }

  logout() {
    sessionStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }
}
