<div id="pills-groups" role="tabpanel" aria-labelledby="pills-groups-tab">
  <!-- Start Groups content -->
  <div>
    <div class="p-4">
      <div class="user-chat-nav float-end">
        <div ngbTooltip="{{'forms.modal.add' | translate}}">
          <!-- Button trigger modal -->
          <button type="button" class="btn btn-link text-decoration-none text-muted font-size-18 py-0"
                  data-toggle="modal" data-target="#addgroup-modal" (click)="createForm(content)">
            <i class="ri-add-circle-fill me-1 ms-0"></i>
          </button>
        </div>
      </div>
      <h4 class="mb-4">Forms
      </h4>

      <!-- Start add group Modal -->
      <ng-template #content let-modal>
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title font-size-16" id="addgroup-modalLabel">
              {{ 'forms.modal.add' | translate }}</h5>
            <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"
                    (click)="modal.dismiss('Cross click')">
            </button>
          </div>
          <div class="modal-body p-4" *ngIf="selectedForm">
            <div>
              <div class="mb-4">
                <label class="form-label">{{ 'forms.modal.status' | translate }}</label>
                <input type="text" class="form-control" [(ngModel)]="selectedForm.status"/>
              </div>
              <div class="mb-4">
                <label class="form-label">{{ 'forms.modal.lang' | translate }}</label>
                <select class="form-control" [(ngModel)]="selectedForm.lang">
                  <option value="pl">{{ 'forms.modal.lang.pl' | translate }}</option>
                  <option value="en">{{ 'forms.modal.lang.en' | translate }}</option>
                  <option value="ua">{{ 'forms.modal.lang.ua' | translate }}</option>
                </select>
              </div>

              <div class="row">
                <div class="col-8">
                  <p>
                    <button class="btn btn-primary" (click)="add()">{{ 'forms.modal.new.item' | translate }}</button>
                  </p>
                  <table>
                    <thead>
                    <tr>
                      <th>{{ 'forms.modal.item.name' | translate }}</th>
                      <th>{{ 'forms.modal.item.required' | translate }}</th>
                      <th>{{ 'forms.modal.item.type' | translate }}</th>
                      <th>{{ 'forms.modal.item.allowed.values' | translate }}</th>
                      <th>{{ 'forms.modal.item.action' | translate }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let x of selectedForm.values; let index = index">
                      <td>
                        <input type="text" class="form-control" [(ngModel)]="x.name">
                        <input type="text" class="form-control" [(ngModel)]="x.key"
                               placeholder="{{  'forms.modal.item.key' | translate }}">
                      </td>
                      <td>
                        <select type="text" class="form-control" [(ngModel)]="x.required">
                          <option value="true">{{ 'forms.modal.item.yes' | translate }}</option>
                          <option value="false">{{ 'forms.modal.item.no' | translate }}</option>
                        </select>
                      </td>
                      <td>
                        <div class="row">
                          <div class="col">
                            <select type="text" class="form-control" [(ngModel)]="x.type">
                              <option value="text">{{ 'forms.modal.item.text' | translate }}</option>
                              <option value="select">{{ 'forms.modal.item.select' | translate }}</option>
                              <option value="photo">{{ 'forms.modal.item.photo' | translate }}</option>
                            </select>
                          </div>
                          <div class="col" *ngIf="x.type == 'text'">
                            <select type="text" class="form-control" [(ngModel)]="x.inputType">
                              <option value="">{{ 'forms.modal.item.text' | translate }}</option>
                              <option value="number">{{ 'forms.modal.item.numeric' | translate }}</option>
                            </select>
                          </div>
                        </div>
                      </td>
                      <td>
                        <button type="button" class="btn btn-info btn-sm"
                                (click)="addValue(index)"
                                *ngIf="x.type == 'select'">{{ 'forms.modal.item.add' | translate }}
                        </button>
                        <ul>
                          <li *ngFor="let value of x.allowedValues;let index2 = index">{{ value }}

                            <i class="ri-pencil-fill" (click)="editValue(index,index2)"></i>
                            <i class="ri-delete-bin-fill" (click)="removeValue(index,index2)"></i>
                          </li>
                        </ul>


                      </td>
                      <td (click)="removeItem(index)">{{ 'forms.modal.item.remove' | translate }}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <div class="col-4">
                  <h6>{{ 'forms.modal.item.preview' | translate }}</h6>
                  @for (x of selectedForm.values; track $index) {
                    <div class="mb-4">
                      <label class="form-label" [for]="x.name">{{ x.name }}</label>
                      <input [type]="x.inputType == 'number' ? 'number' : 'text' " [id]="x.name" class="form-control"
                             *ngIf="x.type == 'text'">
                      <select class="form-control" *ngIf="x.type == 'select'">
                        @for (y of x.allowedValues; track $index) {
                          <option> {{ y }}</option>
                        }
                      </select>
                      @if (x.type == 'photo') {
                        <p>
                          <button class="btn btn-primary">{{ 'forms.modal.item.add' | translate }}</button>
                        </p>
                      }
                    </div>
                  }
                </div>
              </div>

            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-link" data-dismiss="modal" (click)="modal.dismiss('Cross click')">
              {{ 'forms.modal.item.cancel' | translate }}
            </button>
            <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="deleteForm()">
              {{ 'forms.modal.item.remove.form' | translate }}
            </button>
            <button type="button" class="btn btn-primary"
                    (click)="save()"
            >{{ 'forms.modal.item.add' | translate }}
            </button>
          </div>
        </div>
      </ng-template>
      <!-- End add group Modal -->

    </div>

    <!-- Start chat-group-list -->
    <ngx-simplebar class="p-4 chat-message-list chat-group-list" data-simplebar>
      <ul class="list-unstyled chat-list">
        @for (item of forms; track $index) {
          <li (click)="editForm(item, content)">
            <a href="javascript:void(0);">
              <div class="d-flex align-items-center">
                <div class="chat-user-img me-3 ms-0">
                  <div class="avatar-xs">
                        <span class="avatar-title rounded-circle bg-primary-subtle text-primary">
                          {{ (item.status | translate).charAt(0) }}
                        </span>
                  </div>
                </div>
                <div class="flex-grow-1 overflow-hidden">
                  <h5 class="text-truncate font-size-14 mb-0">
                    {{ 'forms.modal.status' | translate }}: {{ item.status }}
                  </h5> <h5 class="text-truncate font-size-14 mb-0">
                  {{ 'forms.modal.lang' | translate }}: {{ item.lang }}
                </h5>
                </div>
              </div>
            </a>
          </li>
        }
      </ul>
    </ngx-simplebar>
    <!-- End chat-group-list -->
  </div>
  <!-- End Groups content -->
</div>
