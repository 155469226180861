import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {FormBuilder, Validators, FormGroup} from '@angular/forms';
import {AuthService} from "../../core/services/auth.service";
import {Conversations} from "../../core/models/conversation.model";
import {NgxPermissionsService} from "ngx-permissions";

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})

/**
 * Chat-component
 */
export class IndexComponent implements OnInit {
  activetab = -1;
  conversation: Conversations;
  formData!: FormGroup;
  @ViewChild('scrollRef') scrollRef: any;
  emoji = '';
  mode: string | undefined;

  listLang = [
    {text: 'Polski', flag: 'assets/images/flags/us.jpg', lang: 'pl'},
    {text: 'Angielski', flag: 'assets/images/flags/en.jpg', lang: 'en'},
    {text: 'Ukraiński', flag: 'assets/images/flags/ua.jpg', lang: 'ua'}
  ];

  lang: string;
  images: { src: string; thumb: string; caption: string }[] = [];

  constructor(private authService: AuthService,
              private permissionService: NgxPermissionsService,
              private router: Router, public translate: TranslateService,
              public formBuilder: FormBuilder) {
  }

  async ngOnInit(): Promise<void> {
    this.activetab = parseInt(localStorage.getItem("activeTab")) ?? -1;

    document.body.setAttribute('data-bs-theme', 'light');

    this.formData = this.formBuilder.group({
      message: ['', [Validators.required]],
    });

    const user = window.sessionStorage.getItem('currentUser');
    if (!user) {
      this.router.navigate(['/account/login']);
    }

    this.lang = this.translate.currentLang;
    this.onListScroll();
  }

  closeUserChat() {
    document.getElementById('chat-room').classList.remove('user-chat-show');
    document.getElementById('chat-room').classList.add('d-none');
  }

  logout() {
    this.authService.logout();

    this.router.navigate(['/account/login']);
  }

  setLanguage(lang) {
    this.translate.use(lang);
    this.lang = lang;
  }

  setActive(index: number){
    this.activetab = index;
    localStorage.setItem("activeTab", String(index));
    this.closeUserChat();
  }

  get form() {
    return this.formData.controls;
  }

  onListScroll() {
    if (this.scrollRef !== undefined) {
      setTimeout(() => {
        this.scrollRef.SimpleBar.getScrollElement().scrollTop = this.scrollRef.SimpleBar.getScrollElement().scrollHeight;
      }, 500);
    }
  }

  changeMode(mode: string) {
    this.mode = mode;
    switch (mode) {
      case 'light':
        document.body.setAttribute('data-bs-theme', "light");
        break;
      case 'dark':
        document.body.setAttribute('data-bs-theme', "dark");
        break;
      default:
        document.body.setAttribute('data-bs-theme', "light");
        break;
    }
  }

}
