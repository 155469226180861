import {Component,OnInit} from '@angular/core';

import {AppContext} from "../../core/services/app.context";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {OperatorService} from "../../core/services/operator.service";
import {AgreementsModel} from "../../core/models/agreements.model";
import {AgreementService} from "../../core/services/agreement.service";
import {CreateOperatorRequest} from "../../core/models/create.operator.request";
import {NotifierService} from "angular-notifier-3";
import {EditAgreements} from "../../core/models/edit.agreements";
import {OperatorModel} from "../../core/models/operator.model";

@Component({
  selector: 'app-agreement',
  templateUrl: './agreement.component.html',
  styleUrls: ['./agreement.component.scss']
})

/**
 * Chat-component
 */
export class AgreementComponent implements OnInit {

  searchQuery = '';
  agreements: AgreementsModel[];
  editId = 0;
  selectedAgreement: AgreementsModel;

  constructor(private modalService: NgbModal,
              private notifierService: NotifierService,
              private appContext: AppContext,
              private operatorService: OperatorService,
              private agreementService: AgreementService,) {
  }

  deleteOperator(operator: OperatorModel){
    this.operatorService.deleteOperator(operator.id).subscribe(data => {
      this.loadAgreements();
    }, error => {
      this.notifierService.notify(error.error.status, error.error.message);
    });
  }

  loadAgreements() {
    this.agreementService.getAgreements().subscribe(data => {
      this.agreements = data;
      this.agreements.forEach(a => {
        if (a.id == this.selectedAgreement.id){
          this.selectedAgreement = a;
        }
      })
    }, error => {
      this.notifierService.notify(error.error.status, error.error.message);
    });
  }

  editAgreement(agreement: AgreementsModel, content: any) {
    this.editId = agreement.id;
    this.selectedAgreement = agreement;
    this.selectedAgreement.data = new CreateOperatorRequest();
    this.openModal(content);
  }

  delete(id: number){
    if (confirm('czy chcesz usunac umowe?')) {
      this.agreementService.deleteAgreement(id).subscribe(data => {
        this.modalService.dismissAll();
        this.loadAgreements();
      }, error => {
        this.notifierService.notify(error.error.status, error.error.message);
      });
    }
  }

  createAgreementApi() {
    this.agreementService.createAgreement(this.selectedAgreement).subscribe(data => {
      this.modalService.dismissAll();
      this.loadAgreements();
    }, error => {
      this.notifierService.notify(error.error.status, error.error.message);
    });
  }
  editAgreementApi() {
    const body: EditAgreements = {
      id: this.editId,
      name: this.selectedAgreement.name,
      email: this.selectedAgreement.email
    }
    this.agreementService.editAgreement(body).subscribe(data => {
      this.modalService.dismissAll();
      this.loadAgreements();
    }, error => {
      this.notifierService.notify(error.error.status, error.error.message);
    });
  }

  save(){
    if (this.editId == 0){
      this.createAgreementApi();
    }else {
      this.editAgreementApi();
    }
  }

  create(content:any) {
    this.editId = 0;
    this.selectedAgreement = new AgreementsModel(0);
    this.openModal(content);
  }

  ngOnInit(): void {
    this.loadAgreements();
  }


  openModal(content: any) {
    this.modalService.open(content, {centered: true});
  }

  createShipper(){
    const body: CreateOperatorRequest = {
      username: this.selectedAgreement.data.username,
      password: this.selectedAgreement.data.password,
      role: 'SHIPPER',
      agreementId: this.selectedAgreement.id
    }
    this.operatorService.createOperator(body).subscribe(data => {
      this.loadAgreements();
    }, error => {
      this.notifierService.notify("error","Wystąpił bląd");
    });
  }

}
