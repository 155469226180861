import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {PermissionService} from "./core/services/permission.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(public translate: TranslateService,
              private permissionService: PermissionService) {
    translate.addLangs(['pl', 'en', 'ua',]);
    translate.setDefaultLang('pl');

    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/pl|en|ua/) ? browserLang : 'pl');

  }
}
